import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Images from "react-bootstrap/Image";
import {
  inputFormContainer,
  headerTitle,
  productInfoText,
} from "../components/layout.module.css";
import Layout from "../components/layout";
import axios from "axios";
import queryString from "query-string";
import Swal from "sweetalert2";
import Moment from "moment";
import { PageProps } from "gatsby";
import parse from "html-react-parser";

interface WarrantyDetailResponse {
  productName: string;
  thumbnail: string;
  productLotNo: string;
  manufacturingDate: string;
  expiryDate: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  purchaseDate: string;
  storeName: string;
  // storeState: string;
  termCondition: string;
}

const warrantyDetails = ({ location }: PageProps) => {
  const [value, setValue] = React.useState<WarrantyDetailResponse>();
  const queryParams = queryString.parse(location.search);
  const refId = queryParams.ref;
  if (refId) {
    React.useEffect(() => {
      if (refId && value == null) {
        axios
          .get(`${process.env.API_URL}/warranty/details/${refId}`)
          .then(function (response) {
            if ((response.status = 200)) {
              const data = response.data.data as WarrantyDetailResponse;
              setValue(data);
            }
          })
          .catch(function (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data,
                confirmButtonColor: "#01B5BB",
              });
            } else if (error.request) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                confirmButtonColor: "#01B5BB",
              });
              console.log(error.request);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                confirmButtonColor: "#01B5BB",
              });
              console.log("Error", error.message);
            }
          });
      }
    });
    return (
      <Layout>
        <div className={inputFormContainer}>
          <h5 className={headerTitle}> Warranty information </h5>
          <Container fluid>
            <Row>
              <strong>Product Information</strong>
            </Row>
            <Row>
              <Col>
                <Images
                  alt="product"
                  src={value?.thumbnail}
                  width="150px"
                  height="auto"
                />
              </Col>
              <Col className={productInfoText}>
                <p>
                  <strong>Product model</strong>
                </p>
                <p>{value?.productName}</p>
                <p>
                  <strong>Product Lot No./ Serial No.</strong>
                </p>
                <p>{value?.productLotNo}</p>
                {/* <p>
                  <strong>Manufacturing date</strong>
                </p>
                <p>
                  {value?.manufacturingDate
                    ? Moment(value?.manufacturingDate).format("DD/MM/YYYY")
                    : "N/A"}
                </p>
                <p>
                  <strong>Expiry date</strong>
                </p>
                <p>
                  {value?.expiryDate
                    ? Moment(value?.expiryDate).format("DD/MM/YYYY")
                    : "N/A"}
                </p> */}
              </Col>
            </Row>
          </Container>
          <Container className="mt-3">
            <Row>
              <strong>Product Owner Detail</strong>
            </Row>
            <Row className={productInfoText}>
              <Container>
                <Row>
                  <Col>
                    <strong>First name</strong>
                  </Col>
                  <Col>{value?.firstName}</Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Last name</strong>
                  </Col>
                  <Col>{value?.lastName}</Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Mobile phone</strong>
                  </Col>
                  <Col>{value?.mobile}</Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Email</strong>
                  </Col>
                  <Col style={{ overflowWrap: "anywhere" }}>{value?.email}</Col>
                </Row>
              </Container>
            </Row>
          </Container>
          <Container className="mt-3">
            <Row>
              <strong>Purchase Detail</strong>
            </Row>
            <Row className={productInfoText}>
              <Container>
                <Row>
                  <Col>
                    <strong>Date of purchase</strong>
                  </Col>
                  <Col>{Moment(value?.purchaseDate).format("DD/MM/YYYY")}</Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Store</strong>
                  </Col>
                  <Col>
                    {value?.storeName} <br />{" "}
                    {/* {value?.storeState.toLocaleUpperCase()} */}
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
          <Container className="mt-3">
            <Row>
              <strong>Terms & Conditions</strong>
            </Row>
            <Row className={productInfoText}>
              <Col>{parse(value?.termCondition ?? "")}</Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  } else {
    return <Layout></Layout>;
  }
};

export default warrantyDetails;
